<template>
	<v-card>
		<v-toolbar dense color="primary">
			<v-toolbar-title class="white--text">Cambio de estado</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon color="white" @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<div class="pa-4">
			<span class="text-h6">Impagados: {{ impagados.join(", ") }}</span>

			<v-list dense>
				<v-list-item
					@click="nuevoEstado = estado"
					v-for="estado in estados"
					:key="estado.idEstadoImpagado"
				>
					<status-chip :value="estado.estado"></status-chip>
				</v-list-item>
			</v-list>

			<template v-if="nuevoEstado && nuevoEstado.estado && Boolean(Number(nuevoEstado.requiereVencimiento))">
				<v-subheader>Fecha vencimiento</v-subheader>
				<v-divider />
				<v-date-picker
					class="mt-1"
					full-width
					landscape
					v-model="nuevoEstado.vencimiento"
					dense
					:min="[new Date().getFullYear(), ('00' + (new Date().getMonth() + 1)).slice(-2), ('00' + new Date().getDate()).slice(-2)].join('-')"
				></v-date-picker>
			</template>

			<template v-if="nuevoEstado.estado">
				<v-divider></v-divider>
				<div class="d-flex align-center">
					<v-card-title>Cambiar estado a :</v-card-title>
					<status-chip :key="nuevoEstado.estado" :value="nuevoEstado.estado"></status-chip>
					<v-spacer></v-spacer>
					<v-btn :disabled="Boolean(Number(nuevoEstado.requiereVencimiento)) && !nuevoEstado.vencimiento" @click.stop="cambiarEstado" color="primary" outlined>Aceptar</v-btn>
				</div>
			</template>
		</div>
	</v-card>
</template>

<script>
export default {
	components: {
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	props: {
		impagados: { type: Array },
	},
	data() {
		return {
			nuevoEstado: {},
			estados: [],
		};
	},
	async mounted() {
		let str = this.$store.getters.getDatosEmpresa;
		const { IdUsuario, IdAgente } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa
		try {
			var { data: estados } = await axios({
				method: "get",
				url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					getEstados: true
				}
			});
			this.estados = estados;
		} catch (e) {
			console.log(e);
			this.$root.$emit("snack", "Ha ocurrido un error");
		}
	},
	methods: {
		async cambiarEstado() {
			if (!confirm('¿Estás seguro de que quieres cambiar estos impagados a ' + this.nuevoEstado.estado + '?')) return;
			await axios({
				method: "PUT",
				url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					impagados: this.impagados,
					nuevoEstado: this.nuevoEstado.idEstadoImpagado,
					nuevoVencimiento: this.nuevoEstado.vencimiento ? parseDate(this.nuevoEstado.vencimiento, true) : null
				},
			})
				.then((res) => {
					this.$root.$emit("snack", "Se ha cambiado el estado con éxito");
					this.cambioEstado = false;
					this.$emit('close');
					this.$emit('reload');
				})
				.catch((err) => {
					console.error(err);
					this.$root.$emit("snack", "No se ha podido cambiar el estado");
				});
		},
	},
};
</script>

<style>
</style>